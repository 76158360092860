import LazyImage, { Type } from '@/components/lazy-image'
import { t } from '@lingui/macro'
import { oss_svg_image_domain_address } from '@/constants/oss'
import { ErrorPageCodeEnum } from '@/constants/base'
import styles from './index.module.css'

interface IErrorPageProps {
  /** 404、403 错误码 */
  errorCode?: ErrorPageCodeEnum
  errorInfo?: string
}
export function ErrorPage({ errorInfo, errorCode }: IErrorPageProps) {
  const errorPageStyle = 'flex flex-col justify-center items-center mt-52 mb-44'

  if (errorCode && Object.values(ErrorPageCodeEnum).includes(errorCode)) {
    return (
      <div className={styles['error-page-wrap']}>
        <LazyImage
          whetherManyBusiness={errorCode === ErrorPageCodeEnum.is404}
          src={`${oss_svg_image_domain_address}icon_default_${errorCode}`}
          imageType={Type.png}
          imageName={`${errorCode}~`}
          hasTheme
          whetherPlaceholdImg={false}
        />
        <div className="error-page-text">{errorInfo}</div>
      </div>
    )
  }

  return (
    <div className={styles['error-page-wrap']}>
      <LazyImage
        src={`${oss_svg_image_domain_address}icon_default_load_fail`}
        imageType={Type.png}
        imageName={t`user.pageContent.title_04`}
        hasTheme
        whetherPlaceholdImg={false}
        whetherManyBusiness
      />
    </div>
  )
}
